<template>
    <v-container>
		<v-row wrap align="center" justify="space-between">
			<v-col cols="12" md="6">
				<v-btn
					small
					elevation="4"
					dark
					color="red acent-3"
					tile
					@click="exportSiteMap"
				>
					Export sitemap
				</v-btn>
			</v-col>
		</v-row>
        <v-row wrap align="center" justify="space-between">
            <v-col cols="12" md="4">
					<v-btn
						router
						:to="{name: 'admin.concursos.create'}"
						small
						elevation="4"
						dark
						color="red acent-3"
						tile
					>
						<v-icon small class="mr-2">mdi-plus</v-icon>
						Adicionar
					</v-btn>
				</v-col>

				<v-col md="3" sm="12">
					<search-content-componet @searchContentEvent="searchContentEvent"/>
					
				</v-col>
        </v-row>
		<v-row v-if="search_alert">
			<v-col cols="12">
                <v-alert v-if="concursos.total > 0" dense text type="success" border="left">
                  

					<v-row justify="space-between">
						<v-col>
							<span class="caption"
							>{{ concursos.total }} resultados de {{ search }}.
						</span>
						</v-col>
						<v-col class="text-right">
							<v-btn @click="resetSearchResults" color="success" small text
							>Limpar <v-icon small>mdi-close</v-icon></v-btn>
						</v-col>
					</v-row>
                </v-alert>

                <v-alert v-else dense text type="error" border="left">
                  

					<v-row justify="space-between">
						<v-col>
							<span class="caption"
								>{{ concursos.total }} resultados de {{ search }}.
							</span>
						</v-col>
						<v-col class="text-right">
							<v-btn @click="resetSearchResults" color="error" small text>
								Limpar 
							<v-icon small>mdi-close</v-icon></v-btn>
						</v-col>
					</v-row>
                </v-alert>
            </v-col>
        </v-row>
        <v-row wrap justify="space-between">
			<v-col
				sm="12"
				md="6"
				v-for="concurso in concursos.data"
				:content="concurso"
				:key="concurso.id"
				class="mb-3"
			>
					<card-component
						:content="concurso"
						routeDetails="admin.concursos.show"
					></card-component>
			</v-col>
		</v-row>
        <v-row>
			<v-col xs="12">
				<v-pagination
					v-model="concursos.current_page"
					:length="concursos.last_page"
					@input="handleChangePage"
					color="error"
					total-visible="7"
				></v-pagination>
			</v-col>
		</v-row>
    </v-container>
</template>

<script>
import api from '../../../../services/api'
import CardComponent from '../general/CardComponent.vue'
import searchContentComponet from '../general/searchContentComponent.vue'
export default {
    components: {CardComponent, searchContentComponet},
    data() {
        return {
			search: '',
			search_alert: false
        }
    },
    created() {
        this.getConcursos(1)
    },
    methods: {
        getConcursos(page) {
            this.$store.dispatch("concursos/getConcursos", {
				page
			})
        },
		handleChangePage(page) {
			if(this.search){
				this.$store.dispatch("concursos/filterConcursos", {
					page,
					filter: this.search
				})
			}else {
				this.getConcursos(page);
			}
		},
		searchContentEvent(filter){
			this.$store.dispatch("concursos/filterConcursos", {
				page: 1,
				filter: filter
			}).then(() => {
				this.search = filter
				this.search_alert = true
			})
		},
		resetSearchResults(){
			this.getConcursos(1)
			this.search =""
			this.search_alert = false
		},
		exportSiteMap() {
			this.$store.dispatch('loading/showLoader');
			var doc = document.implementation.createDocument('', '', null);
			var urlset = doc.createElement("urlset");
			urlset.setAttribute("xmlns", "http://www.sitemaps.org/schemas/sitemap/0.9");
			var url = ''; var changefreq = ''; var loc = ''; var lastmod = ''; var priority = '';
			var staticSites = ['', 'empresas', 'recruiter/login', 'recruiter/register'];

			for (var i=0; i < staticSites.length; i++) {
				url = doc.createElement("url");
				loc = doc.createElement("loc");
				loc.innerHTML = 'https://empresas.gugla.co.mz/' + staticSites[i];
				changefreq = doc.createElement("changefreq");
				changefreq.innerHTML = 'monthly';
				url.appendChild(loc);
				url.appendChild(changefreq);
				urlset.appendChild(url);
			}
			api.get('recruiters/allContents').then(res => {
				let concursos = res.data.data.concursos;
				let empresas = res.data.data.empresas;
				for (let i = 0; i < concursos.length; i++) {
					url = doc.createElement("url");
					loc = doc.createElement('loc');
					loc.innerHTML = 'https://empresas.gugla.co.mz/contents/' + concursos[i].id + "/show";
					lastmod = doc.createElement("lastmod");
					lastmod.innerHTML = concursos[i].updated_at;
					priority = doc.createElement("priority");
					priority.innerHTML = '0.9';
					changefreq = doc.createElement("changefreq");
					changefreq.innerHTML = 'weekly';
					url.appendChild(loc);
					url.appendChild(lastmod);
					url.appendChild(changefreq);
					url.appendChild(priority);
					urlset.appendChild(url);
				}
				for (let i = 0; i < empresas.length; i++) {
					url = doc.createElement("url");
					loc = doc.createElement('loc');
					loc.innerHTML = 'https://empresas.gugla.co.mz/empresa/' + empresas[i].id + "/show";
					lastmod = doc.createElement("lastmod");
					lastmod.innerHTML = empresas[i].updated_at;
					priority = doc.createElement("priority");
					priority.innerHTML = '0.9';
					changefreq = doc.createElement("changefreq");
					changefreq.innerHTML = 'monthly';
					url.appendChild(loc);
					url.appendChild(lastmod);
					url.appendChild(changefreq);
					url.appendChild(priority);
					urlset.appendChild(url);
				}
				doc.appendChild(urlset);
				//console.log(doc)
				//serialize the xml file to txt
				var oSerializer = new XMLSerializer();
				// eslint-disable-next-line no-unused-vars
				var xmltext = oSerializer.serializeToString(doc);
				console.log(xmltext)
				xmltext = '<?xml version="1.0" encoding="UTF-8"?>' + xmltext;
				//download the file
				var pom = document.createElement('a');
				var filename = "sitemap.xml";
				var bb = new Blob([xmltext], {type: 'text/plain'});
				pom.setAttribute('href', window.URL.createObjectURL(bb));
				pom.setAttribute('download', filename);
				pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
				pom.draggable = true; 
				pom.classList.add('dragout');
				pom.click();
				this.$store.dispatch('loading/closeLoader');
			})
		}
    },
	computed: {
		concursos() {
			return this.$store.state.concursos.items
		}
		
	}
}
</script>

<style>

</style>