/* eslint-disable vue/no-unused-components */
<template>
    <v-container>
		<v-row wrap align="center" justify="space-between">
			<v-col cols="12" md="6">
				<v-btn
					small
					elevation="4"
					dark
					color="red acent-3"
					tile
					@click="exportSiteMap"
				>
					Export sitemap
				</v-btn>
			</v-col>
		</v-row>
        <v-row wrap align="center" justify="space-between">
            <v-col cols="12" md="6">
					<v-btn
						router
						:to="{name: 'admin.vagas.create'}"
						small
						elevation="4"
						dark
						color="red acent-3"
						tile
					>
						<v-icon small class="mr-2">mdi-plus</v-icon>
						Adicionar
					</v-btn>
				</v-col>

				<v-col md="3" sm="12">
					<search-content-componet @searchContentEvent="searchContentEvent"/>
					
				</v-col>
        </v-row>
		<!--<v-row align="center" justify="space-between">
			<v-col md="3" sm="12">
				<search-content-componet @searchContentEvent="searchContentEvent"/>
				
			</v-col>
		</v-row>-->
		<v-row v-if="search_alert">
			<v-col cols="12">
                <v-alert v-if="vagas.total > 0" dense text type="success" border="left">
                  

					<v-row justify="space-between">
						<v-col>
							<span class="caption"
							>{{ vagas.total }} resultados de {{ search }}.
						</span>
						</v-col>
						<v-col class="text-right">
							<v-btn @click="resetSearchResults" color="success" small text
							>Limpar <v-icon small>mdi-close</v-icon></v-btn>
						</v-col>
					</v-row>
                </v-alert>

                <v-alert v-else dense text type="error" border="left">
                  

					<v-row justify="space-between">
						<v-col>
							<span class="caption"
								>{{ vagas.total }} resultados de {{ search }}.
							</span>
						</v-col>
						<v-col class="text-right">
							<v-btn @click="resetSearchResults" color="error" small text>
								Limpar 
							<v-icon small>mdi-close</v-icon></v-btn>
						</v-col>
					</v-row>
                </v-alert>
            </v-col>
        </v-row>
		<v-row wrap justify="space-between">
			<v-col
				sm="12"
				md="6"
				v-for="vaga in vagas.data"
				:content="vaga"
				:key="vaga.id"
				class="mb-3"
			>
					<card-component
						:content="vaga"
						routeDetails="admin.vagas.show"
					></card-component>
			</v-col>
		</v-row>
		<v-row>
				<v-col xs="12">
					<v-pagination
						v-model="vagas.current_page"
						:length="vagas.last_page"
						@input="handleChangePage"
						color="error"
						total-visible="7"
					></v-pagination>
				</v-col>
			</v-row>
    </v-container>
</template>

<script>
import CardComponent from '../general/CardComponent.vue'
import searchContentComponet from '../general/searchContentComponent.vue'
import api from '../../../../services/api'
export default {
	// eslint-disable-next-line vue/no-unused-components
	components: {CardComponent, searchContentComponet},
	data() {
		return {
			search: '',
			search_alert: false,
			allContent : []
		}
	},
	created() {
		this.getVagas(1)
	},
	methods: {
		getVagas(page) {
			
			this.$store.dispatch("vagas/getVagas", {
				page
			})
			
			
		},
		handleChangePage(page) {
			if(this.search){
				this.$store.dispatch("vagas/filterVagas", {
					page,
					filter: this.search
				})
			} else {
				this.getVagas(page);
			}
			
		},
		searchContentEvent(filter){
			this.$store.dispatch("vagas/filterVagas", {
				page: 1,
				filter: filter
			}).then(() => {
				this.search = filter
				this.search_alert = true
			})
			console.log(filter)
		},
		resetSearchResults(){
			this.getVagas(1)
			this.search =""
			this.search_alert = false
		},
		getAllContent() {
			return new Promise(() => {
				api.get('guest/allContents').then(res => {
					this.allContent = res.data.data
					//console.log(this.allContent)
				}).catch(err => console.log(err))
			})
			
			
		},
		exportSiteMap() {
			this.$store.dispatch('loading/showLoader');
			var doc = document.implementation.createDocument('', '', null);
			var urlset = doc.createElement("urlset");
			urlset.setAttribute("xmlns", "http://www.sitemaps.org/schemas/sitemap/0.9");
			var url = ''; var changefreq = ''; var loc = ''; var lastmod = ''; var priority = '';
			var staticSites = ['', 'candidate/login', '', 'candidate/register'];

			for (var i=0; i < staticSites.length; i++) {
				url = doc.createElement("url");
				loc = doc.createElement("loc");
				loc.innerHTML = 'https://empregos.gugla.co.mz/' + staticSites[i];
				changefreq = doc.createElement("changefreq");
				changefreq.innerHTML = 'monthly';
				url.appendChild(loc);
				url.appendChild(changefreq);
				urlset.appendChild(url);
			}
			//console.log('ok')
			//let contentArray = []
			
			api.get('guest/allContents').then(res => {
				const data = res.data.data
				for(let i = 0; i < data.length; i++) {
					//console.log(data[i].id)
					url = doc.createElement("url");
					loc = doc.createElement('loc');
					loc.innerHTML = 'https://empregos.gugla.co.mz/contents/' + data[i].id + "/show";
					lastmod = doc.createElement("lastmod");
					lastmod.innerHTML = data[i].updated_at;
					priority = doc.createElement("priority");
					priority.innerHTML = '0.9';
					changefreq = doc.createElement("changefreq");
					changefreq.innerHTML = 'weekly';
					url.appendChild(loc);
					url.appendChild(lastmod);
					url.appendChild(changefreq);
					url.appendChild(priority);
					urlset.appendChild(url);
				}
				doc.appendChild(urlset);
				//console.log(doc)
				//serialize the xml file to txt
				var oSerializer = new XMLSerializer();
				// eslint-disable-next-line no-unused-vars
				var xmltext = oSerializer.serializeToString(doc);
				console.log(xmltext)
				xmltext = '<?xml version="1.0" encoding="UTF-8"?>' + xmltext;
				//download the file
				var pom = document.createElement('a');
				var filename = "sitemap.xml";
				var bb = new Blob([xmltext], {type: 'text/plain'});
				pom.setAttribute('href', window.URL.createObjectURL(bb));
				pom.setAttribute('download', filename);
				pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
				pom.draggable = true; 
				pom.classList.add('dragout');
				pom.click();
				this.$store.dispatch('loading/closeLoader');
			})
			//console.log(this.allContent)

			
			
		}
	},
	computed: {
		vagas() {
			return this.$store.state.vagas.items
		}
		
	}
}
</script>

<style>

</style>